var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-collapse',{ref:"refCollapse",staticClass:"pca-sales-item",style:({ minWidth: _vm.width + 'px' }),attrs:{"bordered":""},on:{"change":function($event){return _vm.handleShowDetail(!_vm.show_detail, _vm.item.fulfillment_id)}},model:{value:(_vm.activeKey),callback:function ($$v) {_vm.activeKey=$$v},expression:"activeKey"}},[_c('a-collapse-panel',{key:_vm.item.fulfillment_id,attrs:{"show-arrow":false}},[_c('template',{slot:"header"},[_c('div',{staticClass:"list-item"},[_c('div',{staticClass:"row flex-nowrap align-items-center row-calc fs-13px"},[_c('div',{staticClass:"col text-truncate w-8",on:{"click":e => e.stopPropagation()}},[(_vm.item.show_box)?_c('a-checkbox',{staticClass:"fulfillment-checkbox",on:{"change":_vm.handleItemSubmitted},model:{value:(_vm.isChecked),callback:function ($$v) {_vm.isChecked=$$v},expression:"isChecked"}}):_vm._e()],1),_c('div',{staticClass:"col text-truncate"},[(_vm.item.order_date)?_c('time',{staticClass:"mw-100 d-inline-block align-middle text-truncate",attrs:{"title":_vm.$moment(_vm.item.order_date).format('DD MMM YYYY - HH:mm')}},[_vm._v(" "+_vm._s(_vm.$moment(_vm.item.order_date).format('DD MMM YYYY - HH:mm'))+" ")]):_vm._e()]),_c('div',{staticClass:"col text-truncate"},[_c('div',{staticClass:"mw-100 d-inline-block align-middle text-truncate",attrs:{"title":_vm.item.order_number}},[_c('a-button',{staticClass:"text-dark",attrs:{"type":"link","size":"small"},on:{"click":e => {
                    e.stopPropagation()
                    _vm.handleCopy('No Order', _vm.item.order_number)
                  }}},[_c('a-icon',{attrs:{"type":"copy"}})],1),_vm._v(" "+_vm._s(_vm.item.order_number)+" ")],1)]),_c('div',{staticClass:"col text-truncate"},[(_vm.item.fulfillment_number)?_c('div',{staticClass:"mw-100 d-inline-block align-middle text-truncate",attrs:{"title":_vm.item.fulfillment_number}},[(_vm.item.fulfillment_number)?_c('a-button',{staticClass:"text-dark",attrs:{"type":"link","size":"small"},on:{"click":e => {
                    e.stopPropagation()
                    _vm.handleCopy('ID Pengiriman', _vm.item.fulfillment_number)
                  }}},[_c('a-icon',{attrs:{"type":"copy"}})],1):_vm._e(),_vm._v(" "+_vm._s(_vm.item.fulfillment_number || '-')+" ")],1):_vm._e()]),_c('div',{staticClass:"col text-truncate"},[(_vm.item.business_name)?_c('div',{staticClass:"mw-100 d-inline-block align-middle text-truncate",attrs:{"title":_vm.item.business_name}},[_vm._v(" "+_vm._s(_vm.item.business_name)+" ")]):_vm._e()]),_c('div',{staticClass:"col text-truncate"},[(_vm.item.channel_name)?_c('div',{staticClass:"mw-100 d-inline-block align-middle text-truncate",attrs:{"title":_vm.item.channel_name}},[_vm._v(" "+_vm._s(_vm.item.channel_name)+" ")]):_vm._e()]),_c('div',{staticClass:"col-10p fs-13px text-truncate"},[_vm._v(" "+_vm._s(_vm.item.items)+" ")]),_c('div',{staticClass:"col-2 px-1 d-flex align-items-center"},[_c('a-button',{staticClass:"text-white px-2 mr-auto fs-10px",style:({ background: _vm.getBackgroundColorStatus }),attrs:{"shape":"round","size":"small"}},[_vm._v(" "+_vm._s(['DELIVERED', 'BUYER_ACCEPTED'].includes(_vm.item.shipping_state) ? 'Terkirim' : _vm.wordingFulfillmentState)+" ")]),_c('a-dropdown',{attrs:{"placement":"bottomRight"}},[_c('a-button',{staticClass:"text-dark font-weight-bold ml-1",staticStyle:{"font-size":"20px","line-height":"0"},attrs:{"type":"link","size":"small"},on:{"click":e => e.stopPropagation()}},[_c('a-icon',{attrs:{"type":"more"}})],1),_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},[_c('a-menu-item',{on:{"click":function($event){return _vm.handleShowDetail(!_vm.show_detail, _vm.item.fulfillment_id)}}},[_vm._v(" "+_vm._s(_vm.show_detail ? 'Tutup' : 'Lihat')+" Detail ")]),(['confirmed', 'accepted'].includes(_vm.item.fulfillment_state.toLowerCase()) 
                      && (_vm.permission.includes('EDIT') || _vm.permission.includes('WRITE'))
                      && !_vm.item.is_ext_wms)?_c('a-menu-item',{on:{"click":() => _vm.$emit('setModalInformationProcessingState', {
                      show: true,
                      orderId: _vm.item.order_id,
                      fulfillmentId: _vm.item.fulfillment_id,
                      state: _vm.item.fulfillment_state.toLowerCase(),
                      action: 'pick-confirmed',
                    })}},[_vm._v(" Diambil ")]):_vm._e(),(_vm.item.fulfillment_state.toLowerCase() === 'pick_confirmed' 
                      && (_vm.permission.includes('EDIT') || _vm.permission.includes('WRITE'))
                      && !_vm.item.is_ext_wms)?_c('a-menu-item',{on:{"click":() => _vm.$emit('setModalInformationProcessingState', {
                      show: true,
                      orderId: _vm.item.order_id,
                      fulfillmentId: _vm.item.fulfillment_id,
                      state: _vm.item.fulfillment_state.toLowerCase(),
                      action: 'pack-confirmed',
                    })}},[_vm._v(" Dikemas ")]):_vm._e(),(['packing_completed'].includes(_vm.item.fulfillment_state.toLowerCase()) 
                      && (_vm.permission.includes('EDIT') || _vm.permission.includes('WRITE'))
                      && !_vm.item.is_ext_wms)?_c('a-menu-item',{on:{"click":() => _vm.$emit('setModalInformationProcessingState', {
                      show: true,
                      orderId: _vm.item.order_id,
                      fulfillmentId: _vm.item.fulfillment_id,
                      state: _vm.item.fulfillment_state.toLowerCase(),
                      action: 'packing-completed',
                    })}},[_vm._v(" Dikirim ")]):_vm._e(),(((_vm.item.fulfillment_state?.toLowerCase() || '') == 'good_issued' 
                      && (_vm.item.shipping_state?.toLowerCase() || '') == 'shipped') 
                      && (_vm.permission.includes('EDIT') || _vm.permission.includes('WRITE')))?_c('a-menu-item',{on:{"click":() => _vm.$emit('setModalInformationProcessingState', {
                      show: true,
                      orderId: _vm.item.order_id,
                      fulfillmentId: _vm.item.fulfillment_id,
                      state: _vm.item.fulfillment_state.toLowerCase(),
                      action: 'good-issued',
                    })}},[_vm._v(" Terkirim ")]):_vm._e(),(['confirmed', 'accepted'].includes(_vm.item.fulfillment_state.toLowerCase()) 
                      && (_vm.permission.includes('EDIT') || _vm.permission.includes('WRITE'))
                      && !_vm.item.is_ext_wms)?_c('a-menu-item',{on:{"click":() => _vm.$emit('setModalInformationProcessingState', {
                      show: true,
                      orderId: _vm.item.order_id,
                      fulfillmentId: _vm.item.fulfillment_id,
                      state: _vm.item.fulfillment_state.toLowerCase(),
                      action: 'packing-completed',
                    })}},[_vm._v(" Dikirim ")]):_vm._e(),(!['created', 'canceled', 'cancel_requested'].includes(_vm.item.fulfillment_state?.toLowerCase())
                      && (_vm.permission.includes('EDIT') || _vm.permission.includes('WRITE')))?_c('a-menu-item',{on:{"click":() => _vm.$emit('setShowModalPrintLabel', {
                      show: true,
                      orderId: _vm.item.order_id,
                      fulfillmentId: _vm.item.fulfillment_id,
                      state: _vm.item.fulfillment_state.toLowerCase(),
                      action: 'print-label',
                    })}},[_vm._v(" "+_vm._s(_vm.$t('fulfillment.printLabel'))+" ")]):_vm._e(),(['confirmed', 'accepted', 'pick_confirmed', 'packing_completed'].includes(_vm.item.fulfillment_state.toLowerCase())
                      && (_vm.permission.includes('EDIT') || _vm.permission.includes('WRITE')))?_c('a-menu-item',{on:{"click":() => _vm.$emit('setModalInformationProcessingState', {
                      show: true,
                      orderId: _vm.item.order_id,
                      fulfillmentId: _vm.item.fulfillment_id,
                      state: _vm.item.fulfillment_state.toLowerCase(),
                      action: 'reject-order', // please set based on modal nedeed
                      channelCode: _vm.item.channel_code,
                      business_id: _vm.item.business_id,
                    })}},[_vm._v(" Batalkan ")]):_vm._e(),(['confirmed', 'accepted', 'pick_confirmed', 'packing_completed', 'good_issued'].includes(_vm.item.fulfillment_state.toLowerCase())
                      && (_vm.permission.includes('EDIT') || _vm.permission.includes('WRITE')) && _vm.item.channel_code === 'tokopedia_id')?_c('a-menu-item',{on:{"click":() => _vm.handleVisible(true, _vm.item)}},[_vm._v(" "+_vm._s(_vm.item.is_automatic_awb ? 'Ubah No. Resi' : 'Masukkan No. Resi')+" ")]):_vm._e()],1)],1)],1)])])]),(_vm.show_detail)?_c('div',{staticClass:"mb-3"},[_c('router-view',{attrs:{"fulfillment_id":_vm.item.fulfillment_id}})],1):_vm._e()],2)],1),_c('a-modal',{attrs:{"title":_vm.rowData.is_automatic_awb ? 'Ubah No. Resi' : 'Input No. Resi',"visible":_vm.visible,"loading":_vm.isLoadingAwb},on:{"ok":_vm.submitAwb,"cancel":() => _vm.handleVisible(false, {})}},[_c('a-input',{attrs:{"placeholder":"Masukkan No. Resi"},model:{value:(_vm.manual_awb),callback:function ($$v) {_vm.manual_awb=$$v},expression:"manual_awb"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }