<template>
  <div>
    <a-collapse
      ref="refCollapse"
      v-model="activeKey"
      bordered
      class="pca-sales-item"
      :style="{ minWidth: width + 'px' }"
      @change="handleShowDetail(!show_detail, item.fulfillment_id)"
    >
      <a-collapse-panel :key="item.fulfillment_id" :show-arrow="false">
        <template slot="header">
          <div class="list-item">
            <div class="row flex-nowrap align-items-center row-calc fs-13px">
              <div class="col text-truncate w-8" @click="e => e.stopPropagation()">
                <a-checkbox
                  v-if="item.show_box"
                  v-model="isChecked"
                  class="fulfillment-checkbox"
                  @change="handleItemSubmitted"
                />
              </div>
              <div class="col text-truncate">
                <time
                  v-if="item.order_date"
                  class="mw-100 d-inline-block align-middle text-truncate"
                  :title="$moment(item.order_date).format('DD MMM YYYY - HH:mm')"
                >
                  {{ $moment(item.order_date).format('DD MMM YYYY - HH:mm') }}
                </time>
              </div>
              <div class="col text-truncate">
                <div
                  class="mw-100 d-inline-block align-middle text-truncate"
                  :title="item.order_number"
                >
                  <a-button
                    type="link"
                    size="small"
                    class="text-dark"
                    @click="e => {
                      e.stopPropagation()
                      handleCopy('No Order', item.order_number)
                    }"
                  >
                    <a-icon type="copy" />
                  </a-button>
                  {{ item.order_number }}
                </div>
              </div>
              <div class="col text-truncate">
                <div
                  v-if="item.fulfillment_number"
                  class="mw-100 d-inline-block align-middle text-truncate"
                  :title="item.fulfillment_number"
                >
                  <a-button
                    v-if="item.fulfillment_number"
                    type="link"
                    size="small"
                    class="text-dark"
                    @click="e => {
                      e.stopPropagation()
                      handleCopy('ID Pengiriman', item.fulfillment_number)
                    }"
                  >
                    <a-icon type="copy" />
                  </a-button>
                  {{ item.fulfillment_number || '-' }}
                </div>
              </div>
              <div class="col text-truncate">
                <div
                  v-if="item.business_name"
                  class="mw-100 d-inline-block align-middle text-truncate"
                  :title="item.business_name"
                >
                  {{ item.business_name }}
                </div>
              </div>
              <div class="col text-truncate">
                <div
                  v-if="item.channel_name"
                  class="mw-100 d-inline-block align-middle text-truncate"
                  :title="item.channel_name"
                >
                  {{ item.channel_name }}
                </div>
              </div>
              <div class="col-10p fs-13px text-truncate">
                {{ item.items }}
              </div>
              <!-- <div class="col text-truncate">
                {{ wordingBookingState }}
              </div> -->
              <div class="col-2 px-1 d-flex align-items-center">
                <a-button
                  shape="round"
                  size="small"
                  class="text-white px-2 mr-auto fs-10px"
                  :style="{ background: getBackgroundColorStatus }"
                >
                  {{ ['DELIVERED', 'BUYER_ACCEPTED'].includes(item.shipping_state) ? 'Terkirim' : wordingFulfillmentState }}
                </a-button>
                <a-dropdown placement="bottomRight">
                  <a-button
                    type="link"
                    size="small"
                    class="text-dark font-weight-bold ml-1"
                    style="font-size: 20px; line-height: 0"
                    @click="e => e.stopPropagation()"
                  >
                    <a-icon type="more" />
                  </a-button>
                  <a-menu slot="overlay">
                    <a-menu-item @click="handleShowDetail(!show_detail, item.fulfillment_id)">
                      {{ show_detail ? 'Tutup' : 'Lihat' }} Detail
                    </a-menu-item>
                    <a-menu-item
                      v-if="['confirmed', 'accepted'].includes(item.fulfillment_state.toLowerCase()) 
                        && (permission.includes('EDIT') || permission.includes('WRITE'))
                        && !item.is_ext_wms"
                      @click="() => $emit('setModalInformationProcessingState', {
                        show: true,
                        orderId: item.order_id,
                        fulfillmentId: item.fulfillment_id,
                        state: item.fulfillment_state.toLowerCase(),
                        action: 'pick-confirmed',
                      })"
                    >
                      Diambil
                    </a-menu-item>
                    <a-menu-item
                      v-if="item.fulfillment_state.toLowerCase() === 'pick_confirmed' 
                        && (permission.includes('EDIT') || permission.includes('WRITE'))
                        && !item.is_ext_wms"
                      @click="() => $emit('setModalInformationProcessingState', {
                        show: true,
                        orderId: item.order_id,
                        fulfillmentId: item.fulfillment_id,
                        state: item.fulfillment_state.toLowerCase(),
                        action: 'pack-confirmed',
                      })"
                    >
                      Dikemas
                    </a-menu-item>
                    <a-menu-item
                      v-if="['packing_completed'].includes(item.fulfillment_state.toLowerCase()) 
                        && (permission.includes('EDIT') || permission.includes('WRITE'))
                        && !item.is_ext_wms"
                      @click="() => $emit('setModalInformationProcessingState', {
                        show: true,
                        orderId: item.order_id,
                        fulfillmentId: item.fulfillment_id,
                        state: item.fulfillment_state.toLowerCase(),
                        action: 'packing-completed',
                      })"
                    >
                      Dikirim
                    </a-menu-item>
                    <a-menu-item
                      v-if="((item.fulfillment_state?.toLowerCase() || '') == 'good_issued' 
                        && (item.shipping_state?.toLowerCase() || '') == 'shipped') 
                        && (permission.includes('EDIT') || permission.includes('WRITE'))"
                      @click="() => $emit('setModalInformationProcessingState', {
                        show: true,
                        orderId: item.order_id,
                        fulfillmentId: item.fulfillment_id,
                        state: item.fulfillment_state.toLowerCase(),
                        action: 'good-issued',
                      })"
                    >
                      Terkirim
                    </a-menu-item>
                    <a-menu-item
                      v-if="['confirmed', 'accepted'].includes(item.fulfillment_state.toLowerCase()) 
                        && (permission.includes('EDIT') || permission.includes('WRITE'))
                        && !item.is_ext_wms"
                      @click="() => $emit('setModalInformationProcessingState', {
                        show: true,
                        orderId: item.order_id,
                        fulfillmentId: item.fulfillment_id,
                        state: item.fulfillment_state.toLowerCase(),
                        action: 'packing-completed',
                      })"
                    >
                      Dikirim
                    </a-menu-item>
                    <a-menu-item
                      v-if="!['created', 'canceled', 'cancel_requested'].includes(item.fulfillment_state?.toLowerCase())
                        && (permission.includes('EDIT') || permission.includes('WRITE'))"
                      @click="() => $emit('setShowModalPrintLabel', {
                        show: true,
                        orderId: item.order_id,
                        fulfillmentId: item.fulfillment_id,
                        state: item.fulfillment_state.toLowerCase(),
                        action: 'print-label',
                      })"
                    >
                      {{ $t('fulfillment.printLabel') }}
                    </a-menu-item>
                    <a-menu-item
                      v-if="['confirmed', 'accepted', 'pick_confirmed', 'packing_completed'].includes(item.fulfillment_state.toLowerCase())
                        && (permission.includes('EDIT') || permission.includes('WRITE'))"
                      @click="() => $emit('setModalInformationProcessingState', {
                        show: true,
                        orderId: item.order_id,
                        fulfillmentId: item.fulfillment_id,
                        state: item.fulfillment_state.toLowerCase(),
                        action: 'reject-order', // please set based on modal nedeed
                        channelCode: item.channel_code,
                        business_id: item.business_id,
                      })"
                    >
                      Batalkan
                    </a-menu-item>
                    <a-menu-item
                      v-if="['confirmed', 'accepted', 'pick_confirmed', 'packing_completed', 'good_issued'].includes(item.fulfillment_state.toLowerCase())
                        && (permission.includes('EDIT') || permission.includes('WRITE')) && item.channel_code === 'tokopedia_id'"
                      @click="() => handleVisible(true, item)"
                    >
                      {{ item.is_automatic_awb ? 'Ubah No. Resi' : 'Masukkan No. Resi' }}
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </div>
            </div>
          </div>
        </template>
        <div
          v-if="show_detail"
          class="mb-3"
        >
          <router-view :fulfillment_id="item.fulfillment_id" />
        </div>
      </a-collapse-panel>
    </a-collapse>
    <a-modal
      :title="rowData.is_automatic_awb ? 'Ubah No. Resi' : 'Input No. Resi'"
      :visible="visible"
      :loading="isLoadingAwb"
      @ok="submitAwb"
      @cancel="() => handleVisible(false, {})"
    >
      <a-input v-model="manual_awb" placeholder="Masukkan No. Resi" />
    </a-modal>
  </div>
</template>

<script>
import { confirmShippingTokopedia } from '@/api/channels/tokopedia'
import {
  urlToDetailFulfillment,
  getUrlAfterDetailFulfillment,
  setBackgroundColorStatus,
  setWordingBookingState,
  setWordingFulfillmentState,
} from '@/utils/fulfillment/index'

export default {
  name: 'DeliveryOrderItem',
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    itemNum: {
      type: Number,
      default: 0,
    },
    itemChecked: {
      type: Boolean,
      default: () => false,
    },
    position: {
      type: Object,
      default: () => {},
    },
    activeCategories: {
      type: String,
      default: () => ('' || 'all'),
    },
    permission: {
      type: Array,
      default: () => {},
    },
    width: {
      type: Number,
      default: 1190,
    },
  },
  data: function () {
    return {
      activeKey: this.$route.query.fulfillment_id ? this.$route.query.fulfillment_id.split(',') : [],
      show_detail: false,
      show_reject: false,
      modalQuery: '',
      minimal_length_slicing_word: 10,
      visible: false,
      rowData: {},
      manual_awb: '',
      isLoadingAwb: false,
      isChecked: false,
    }
  },
  computed: {
    getBackgroundColorStatus() {
      return setBackgroundColorStatus(this.item.fulfillment_state)
    },
    wordingBookingState: function () {
      return setWordingBookingState(this.item.booking_state)
    },
    wordingFulfillmentState: function () {
      return setWordingFulfillmentState(this.item.fulfillment_state, this.item.shipping_state)
    },
    getUrlToDetailFulfillment: function () {
      return urlToDetailFulfillment({
        meta_key_url: this.$route.meta.key,
        id: this.$route.params.id,
        channel_type: this.$route.params.channelType,
      })
    },
    getUrlAfterDetailFulfillmentPage: function () {
      return getUrlAfterDetailFulfillment({
        meta_key_url: this.$route.meta.key,
        id: this.$route.params.id,
        channel_type: this.$route.params.channelType,
      })
    },
    isMasterFulfillment: function () {
      return (
        this.$route.meta.key === 'delivery-order' ||
        this.$route.meta.key === 'delivery-order-detail'
      )
    },
    isHaveActionButton () {
      const fulfillmentState = (this.item.fulfillment_state || '').toLowerCase()
      if (
        [
          'created',
          'pick_confirmed',
          'packing_completed',
        ].includes(fulfillmentState)
      ) {
        this.$emit('setHaveActionButton', true)
        return true
      } else {
        this.$emit('setHaveActionButton', false)
        return false
      }
    },
  },
  watch: {
    item: {
      immediate: true,
      deep: true,
      handler: function (value) {
        if (
          value &&
          this.$route.query.fulfillment_id &&
          this.$route.query.fulfillment_id
            .split(',')
            .find((item) => item === value.fulfillment_id)
        ) {
          this.show_detail = true
        }
        if(value.show_box) {
          this.isChecked = value.submitted
        }
      },
    },
    '$route.query'(newValue, oldValue) {
      if (newValue.fulfillment_state !== oldValue.fulfillment_state) {
        this.show_detail = false
      }
    },
  },
  methods: {
    async submitAwb() {
      this.isLoadingAwb = true
      try {
        await confirmShippingTokopedia({
          order_id: this.rowData.order_id,
          business_id: this.$route.query.business_id,
          data: { awb: this.manual_awb },
        })
      } catch (err) {
        console.log({err})
        this.$message.error('Oops, No. Resi Gagal Disimpan', 5)
        throw err
      } finally {
        this.isLoadingAwb = false
        this.handleVisible(false, {})
        this.manual_awb = ''
      }
    },
    handleVisible(param, item) {
      this.visible = param
      this.rowData = item
    },
    handleItemChecked: function (e) {
      this.$emit('handleItemChecked', {
        checked: e.target.checked,
        order_id: this.item.order_id,
      })
    },
    handleItemSubmitted(e) {
      this.$emit('handleItemSubmitted', {
        index: this.itemNum,
        checked: e.target.checked,
        item: this.item,
      })
    },
    handleShowDetail: function (value, fulfillment_id) {
      const yAxis = window.top.scrollY
      // Get data fulfillment id dari router.
      const { fulfillment_id: routeQueryFulfillmentId } = this.$route.query

      // check condition state show detail
      if (value) {
        // if true => open detail
        this.$router.push({
          path: this.getUrlToDetailFulfillment,
          query: {
            ...this.$route.query,
            // jika di routenya sudah ada list-list detail yang udah kebuka, ditambahkan dengan detail yang terbaru dari param fulfillment_id
            // jika belum ada detail, param fulfillment_id tersebut akan menjadi route di awal.
            fulfillment_id: routeQueryFulfillmentId ? [...routeQueryFulfillmentId.split(','), fulfillment_id].join(',') : fulfillment_id,
          },
        }).then(() => window.scrollTo(0, yAxis))
      } else {
        // if else => unshow detail

        // check fulfillment id yang masih exist di ketika param di filter dengan param fulfillment_id yang akan di unshow
        const fulfillment_id_exist = routeQueryFulfillmentId && routeQueryFulfillmentId.split(',').filter((item) => item !== fulfillment_id)

        if (!fulfillment_id_exist || !fulfillment_id_exist.length) {
          const query = this.$route.query
          delete query.fulfillment_id

          this.$router.push({
            path: this.getUrlAfterDetailFulfillmentPage,
            query,
          }).then(() => window.scrollTo(0, yAxis))
        } else {
          // jika masih ada fulfillment id yang masih exist untuk dibuka
          this.$router.push({
            path: this.$route.path,
            query: {
              ...this.$route.query,
              fulfillment_id: fulfillment_id_exist.join(','),
            },
          }).then(() => window.scrollTo(0, yAxis))
        }
      }

      this.activeKey = fulfillment_id ? fulfillment_id.split(',') : []
      this.show_detail = value
    },
    slicingWording(value) {
      if (value.length <= this.minimal_length_slicing_word) {
        return value
      }

      return `${value.slice(0, this.minimal_length_slicing_word)}...`
    },
    handleCopy(type, val) {
      navigator.clipboard.writeText(val)
      this.$notification.success({
        message: `Copied ${type}: ${val}`,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.pca-sales-item:hover {
  border-color: #ff0a54;

  .ant-collapse-item {
    border-bottom-color: #ff0a54;
  }
}

.row-calc > .col {
  padding-left: 9px;
  padding-right: 9px;
  flex-basis: calc(100% / 6); // 100% / 8
  max-width: calc(100% / 6);
}

.col-10p {
  flex-basis: 10%;
  max-width: 10%;
}

.fs-10px {
  font-size: 10px !important;
}

.fs-13px {
  font-size: 13px !important;
}

.font-1 {
  font-family: Poppins; // font-face
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  white-space: initial;
}

.list-item {
  padding-left: 3px;
  margin-right: -1px;
}

.item {
  margin-bottom: 1.5rem;
  position: static;
  width: 100%;
  height: auto;
  background: #fff;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 10px;
  align-items: center;
  padding: 10px;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  cursor: pointer;
}
.custom_title_ship_text {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 10px;
  white-space: initial;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active di bawah versi 2.1.8 */ {
  opacity: 0;
}
.hr {
  border: none;
  border-top: 2px dotted #eee;
  color: #fff;
  background-color: #fff;
  height: 2px;
}

.red {
  color: red;
}

.refRowDetail {
  max-height: 75vh;
}

.order-list {
  .ant-card-body {
    padding: 0 !important;
    .title {
      padding: .5rem;
      border: 1px solid #d2d2d2;
      box-sizing: border-box;
      border-radius: 8px;
      color: #fff;

      &__content {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 26px;
      }
    }
    .content {
      padding: 1rem;
      font-family: Work Sans;
      font-style: normal;
      font-weight: 500;

      &__title-product {
        font-size: 14px;
        line-height: 16px;
      }

      &__product-support {
        font-size: 10px;
        line-height: 12px;
      }

      &__total-prices {
        font-size: 11px;
        line-height: 13px;
      }

      &__showed-product {
        font-size: 11px;
        line-height: 26px;
      }

      &__calculation {
        &__grand-total {
          background: #eaedec;
          padding: .75rem;
        }

        &__tabs {
          background: #eaedec;
        }

        &__detail-calculation {
          background: #eaedec;
          padding: .75rem;
          border-top: 1px solid #dfdfdf;
          font-family: Work Sans;
          font-style: normal;
          font-size: 11px;
          line-height: 13px;

          .title {
            font-weight: normal;
          }
        }
      }

      &__shipping,
      &__payment {
        &__title {
          font-family: Work Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.pca-sales-item.ant-collapse {
  .ant-collapse-content-box {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ant-collapse-content-active {
    border-top: 0;
  }
}
.w-8 {
  flex-basis: 4% !important;
  max-width: 4% !important;
  -ms-flex-preferred-size: 4% !important;
}
.fulfillment-checkbox .ant-checkbox-inner {
  border-color: #999 !important;
}
</style>
